.stop_covid {
  padding-top: 50px;
  .list_card {
    align-items: center;
    .icon {
      width: 62px;
      height: 62px;
      background: rgba(235, 87, 87, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      svg {
        margin-left: 5px;
        margin-top: 3px;
      }
    }

    .list_card__actions {
      display: flex;
      justify-content: flex-end;
      height: min-content;

      a {
        width: 54px;
        height: 54px;
        border: none;
        color: #2bb472;
        background: rgba(43, 180, 114, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin: 0 !important;
        }
      }
      a:hover {
        color: #fff;
      }
    }
  }
}
