.city_novelty__view {
  width: 730px;
  margin: auto;
  padding-top: 50px;

  & > div {
    margin-bottom: 50px;
    margin-top: 0 !important;
  }
  img {
    width: 300px;
    height: auto;
    margin-right: 15px;
    border: 2px solid rgba(22, 26, 23, 0.08);
    border-radius: 8px;
    cursor: pointer;
  }
  .links {
    margin-top: 30px !important;
  }
  a {
    color: #2bb472;
  }
}
