.bonus {
  display: flex;
  padding-top: 50px;

  & > div:first-child {
    flex: 2;
    & > h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      margin: 0 0 50px 0;
    }
    .list_card {
      width: inherit;
      align-items: center;
      .list_card__actions {
        display: flex;
        justify-content: flex-end;
        height: min-content;

        button {
          min-width: 54px !important;
          width: 54px;
          border: 2px solid rgba(22, 26, 23, 0.08);
          color: #263238;

          .MuiButton-startIcon {
            margin: 0 !important;
          }
        }
      }
    }
  }

  & > div:last-child {
    flex: 1;
    margin-left: 30px;
    height: 190px;
    background: linear-gradient(
      90deg,
      rgba(43, 180, 114, 0.1) 0%,
      rgba(43, 180, 114, 0) 100%
    );
    border-radius: 4px;

    h3 {
      font-weight: bold;
      font-size: 64px;
      line-height: 87px;

      /* Green */
      color: #2bb472;
      margin: 35px 0 5px 65px;
    }

    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-left: 65px;
      margin-top: 0;
    }
  }
}
