.about_project {
  & > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    margin: 60px 0;
  }

  .quote {
    padding: 15px;
    background: rgba(43, 180, 114, 0.1);
    border-radius: 4px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      text-align: right;
    }
  }

  .mission {
    h4 {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      line-height: 36px;
    }
  }
}
