@media (max-width: 1100px) {
  .layout {
    width: inherit !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .header {
    padding: 0 15px;
    width: inherit;
  }
  .menu__wrapper__content,
  .top__inner,
  .slick-dots {
    width: inherit !important;
  }
}

@media (max-width: 768px) {
  .layout {
    width: inherit !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .header {
    padding: 0 15px;
    width: inherit;
    height: 70px;

    .menu__btn {
      span {
        display: none;
      }
    }

    .logo {
      padding-right: 0;
    }

    .lang-picker .lang_btn .MuiIconButton-root {
      font-size: 12px;
    }

    .user_thumb .links {
      padding-left: 10px;
      padding-right: 0;
      font-size: 12px;
    }
  }

  .main_page {
    .text_slides__page {
      h3 {
        font-size: 12px;
      }
      .date {
        font-size: 12px;
      }
    }
    .text_slides .text_slides__info {
      .layout {
        width: inherit;
        padding-top: 120px;
        padding-left: 15px;
      }

      .date {
        font-size: 14px;
      }
      h2 {
        font-size: 20px;
        line-height: 28px;
      }
      .desc {
        font-size: 14px;
        line-height: 20px;
      }
      .slick-dots ul li .text_slides__page {
        padding-left: 15px;
      }
    }

    .links_section {
      padding-top: 50px;
      padding-bottom: 50px;

      h3 {
        font-size: 20px;
        line-height: 28px;
      }

      & > p {
        font-size: 14px;
        line-height: 20px;
      }

      .link_card {
        margin-bottom: 15px;
        padding: 20px;
        height: auto;
        button {
          font-size: 12px;
        }
        p {
          font-size: 16px;
        }
      }
    }

    .news {
      padding-top: 50px;
      padding-bottom: 50px;
      & > div > h3 {
        font-size: 20px;
        line-height: 28px;
      }

      & > div > p {
        font-size: 14px;
        line-height: 20px;
      }

      & > div > .d-flex {
        flex-wrap: wrap;
      }

      & > div .news_card {
        margin-right: 0;
        width: 100%;
        margin-bottom: 30px;
        max-width: 335px;
        img {
          width: inherit;
          max-height: 200px;
        }
      }

      & > div .load_more {
        margin-top: 20px;
      }
    }
  }

  .footer,
  .main_page footer {
    width: inherit !important;
    height: 84px !important;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  .menu__wrapper {
    height: 400px;
    .menu__wrapper__content {
      width: inherit;

      .menu__btn {
        margin-left: 15px;
        margin-top: 20px;
      }

      .links {
        padding-left: 15px;
        margin-top: 20px;
        button {
          margin-top: 15px;
        }
      }
    }
  }

  .auth {
    flex-direction: column;

    .auth__left {
      background-image: none;
      background-color: #fff;
      .auth__left__inner {
        width: inherit;
        margin: 15px;
        display: flex;
        justify-content: center;
        h1 {
          display: none;
        }
      }
    }

    .auth__right .auth__right__inner {
      width: inherit;
      margin: 15px;
    }
  }

  .main .top {
    min-height: 200px;
    .top__inner {
      padding: 20px 0;

      .route_links {
        margin: 0 15px;
        overflow: auto;
        padding-bottom: 10px;
        .parent,
        .child {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .top__title {
        margin: 15px 15px 20px 15px !important;
        font-size: 20px;
        line-height: 28px;
      }

      & > hr {
        margin-left: 15px;
        margin-right: 15px;
      }

      .top__desc {
        font-size: 14px;
        line-height: 20px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .list_card {
    padding: 15px;
    flex-wrap: wrap;
    width: inherit;

    &__info {
      max-width: 61% !important;
      h3 {
        font-size: 16px !important;
      }
    }
    &__actions {
      flex: 1;
    }
  }

  .vote {
    .vote__info {
      padding: 15px;
      .label {
        font-size: 12px;
      }

      .info {
        font-size: 14px;
      }
    }
    .vote_questions {
      .vote_question__label span {
        font-size: 14px;
        line-height: 20px;
      }
      .vote_questions__title {
        font-size: 20px;
        line-height: 28px;
      }

      .vote_questions__answers {
        & > div {
          padding: 5px 5px;
        }
        & > div > div > span {
          display: block;
          width: inherit;
        }
      }
    }
  }

  .suggestion {
    .comments {
      h3 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .comment_container .comment {
        margin-bottom: 15px;
        .MuiCardContent-root p {
          font-size: 14px;
        }
        .MuiCardHeader-root .MuiCardHeader-content {
          .dates {
            font-size: 12px;
          }
          .title {
            font-size: 14px;
          }
        }
      }
    }
    .suggestion__info {
      padding: 15px;
      .label {
        font-size: 12px;
      }

      .info {
        font-size: 14px;
      }
    }
  }

  .city_novelty {
    &__view {
      width: inherit !important;
      padding: 15px 25px !important;

      & > div {
        margin-top: 15px !important;
      }
    }
  }
  .city_novelty,
  .stop_covid {
    padding-top: 15px !important;
    .list_card__actions {
      display: none !important;
    }

    &__view {
      padding-top: 0 !important;
      margin: 15px !important;
    }
  }

  .stop_covid {
    & > h3 {
      font-size: 20px !important;
    }
  }
  .covid_statistics {
    flex-direction: column;

    h3 {
      font-size: 20px !important;
    }

    .left {
      margin-bottom: 30px;
      .statistic {
        margin-top: 15px !important;
        p {
          margin-top: 10px;
          margin-bottom: 0 !important;
          font-size: 24px !important;
        }
        span {
          font-size: 14px !important;
        }
      }
    }
  }
  .main {
    .MuiTabs-scroller {
      padding-bottom: 5px;
      overflow: auto !important;
    }
  }

  .profile_settings > h3,
  .profile_settings > form > h3 {
    font-size: 20px !important;
    margin: 15px 0;
  }

  .user_bonus .user_bonus__banner {
    position: relative;
    & > div {
      display: none;
    }
    .info {
      display: block;
      width: 100%;
      background-image: url(../images/bonus-banner-mobile.png);
      padding: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      h3 {
        font-size: 36px;
      }

      p {
        font-size: 20px;
      }
      button {
        padding: 10px !important;
        margin-top: 15px;
      }
    }
  }

  .user_bonus__history,
  .alert_history {
    & > h3 {
      font-size: 20px !important;
    }
    .list_card__info {
      max-width: none !important;
      flex: 10;
      h3 {
        font-size: 16px !important;
      }
    }
  }

  .city_achievments {
    & > h3,
    .business h4 {
      font-size: 20px !important;
      margin: 15px 0 !important;
    }

    p {
      font-size: 14px !important;
    }

    .business__cards,
    .business__cards2,
    .business__cards3,
    .turism,
    .culture__cards,
    .culture__cards2,
    .sport .cards,
    .science .cards2,
    .science .cards {
      flex-wrap: wrap;
      & > div {
        margin-right: 10px !important;
      }
      p {
        font-size: 14px !important;
      }
    }

    .business__cards2 {
      justify-content: center !important;
    }

    .business__cards3 {
      & > div {
        max-width: 100% !important;
        margin-left: 0 !important;
      }
    }

    .turism__card {
      width: 160px !important;
      height: auto !important;
      margin-bottom: 10px !important;
      p {
        margin: 5px !important;
      }
    }
    .culture__card,
    .sport .card,
    .science .card {
      margin-bottom: 10px !important;
    }
  }

  .about_city {
    & > div > h3 {
      font-size: 20px !important;
      margin: 15px 0 !important;
    }

    .info_card {
      .info {
        .count {
          font-size: 24px !important;
          span {
            font-size: 12px !important;
          }
        }
        h3 {
          font-size: 16px !important;
        }
      }
      .icon {
        width: 100px !important;
        svg {
          width: 45px !important;
        }
      }
    }
  }

  .bonus {
    flex-direction: column-reverse;
    padding-top: 15px !important;
    .total_bonus {
      margin-left: 0 !important;
      margin-bottom: 25px !important;
      padding: 20px !important;
      padding-left: 85px !important;
      h3 {
        font-size: 36px !important;
        line-height: 48px !important;
        margin: 0 !important;
      }

      p {
        font-size: 14px !important;
        line-height: 20px !important;
        margin: 0 !important;
      }
    }

    & > div:first-child > h3,
    .left > h3 {
      font-size: 20px !important;
      line-height: 28px !important;
    }

    .list_card__actions {
      display: none !important;
    }
  }

  .bonus_modal {
    width: inherit !important;
    margin: 60px 15px !important;

    .info span {
      font-size: 16px !important;
    }
  }

  .modal .bonus_modal {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
