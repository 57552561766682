.about_city {
  & > div > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    margin: 50px 0;
    text-align: center;
  }

  .info_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 215px;
    /* width: 100%; */
    height: auto;
    background: #f8faf9;
    border: none;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 30px;
    padding: 16px !important;

    &.row2 {
      min-width: 490px;
      .info {
        max-width: 460px;
      }
    }
    &.row3 {
      min-width: 305px;

      .info {
        max-width: 270px;
      }
    }

    .icon {
      max-width: 45px;
      max-height: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      flex: 1;
      max-width: 215px;

      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        // margin-top: 35px;
      }

      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        /* identical to box height, or 133% */

        /* Green */
        color: #2bb472;
        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          margin-left: 11px;
          display: flex;
          align-items: center;
          .big {
            font-size: 36px;
            line-height: 48px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .white_full {
    // width: 100% !important;
  }

  .green_full {
    // background: rgba(43, 180, 114, 0.1);
    // width: 100% !important;
    // border: none;
  }

  .green {
    // border: none;
    // background: rgba(43, 180, 114, 0.1);
  }
}
