.input_text {
  margin-bottom: 20px;
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #161a17;
  }
}

.input_phone {
  margin-bottom: 20px;
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #161a17;
  }
}
