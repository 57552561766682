.stop_covid {
  padding-top: 50px;
  & > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
  }

  .list_card {
    align-items: center;
    .list_card__actions {
      display: flex;
      justify-content: flex-end;
      height: min-content;

      button {
        min-width: 54px !important;
        width: 54px;
        border: 2px solid rgba(22, 26, 23, 0.08);
        color: #263238;

        .MuiButton-startIcon {
          margin: 0 !important;
        }
      }
    }
  }
}

.covid_statistics {
  display: flex;

  & > div {
    flex: 1;
  }

  h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 0;
  }

  .left {
    display: flex;
    flex-wrap: wrap;

    .statistic {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;

      p {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 10px;
      }

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #161a17;
        opacity: 0.5;
      }
    }
  }
}
