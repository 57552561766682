.alert {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  svg {
    margin-right: 13px;
  }
}

.alert__error {
  background-color: rgba(232, 75, 85, 0.1);
  color: #eb5757;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.load_more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  height: 40px;

  .load_more__icon {
    display: flex;
    align-items: center;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition-duration: 0.5s;
  }
  .load_more__icon:hover {
    cursor: pointer;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-duration: 0.5s;
  }

  &:hover {
    .load_more__icon {
      cursor: pointer;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      transition-duration: 0.5s;
    }
  }
}
