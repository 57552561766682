.city_achievments {
  & > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
  }

  &__desc {
    display: flex;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 90px;
    font-size: 24px;
    line-height: 32px;

    &__icon {
      padding: 60px 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../assets/images/almaty.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: #fff;
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      /* or 133% */

      text-align: center;
    }
  }

  ._block {
    padding: 60px 0;

    &._grey {
      background-color: #f8faf9;
    }

    h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
    }

    p {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .business {
    .business__cards {
      display: flex;
      margin-top: 16px;

      & > div {
        width: 250px;
        text-align: center;
        margin-right: 30px;
      }
      .business__card {
        width: 250px;
        height: auto;
        background: #f9f9fb;
        border-radius: 24px;
        box-sizing: border-box;
        margin-right: 30px;
        padding-top: 30px;

        img {
          display: block;
          margin: auto;
          margin-top: 22px;
          margin-bottom: 20px;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          padding: 20px 20px;
          text-align: center;
          margin: 0;
          background-color: #eeeef4;
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 24px;
          color: #5b5b59;
        }
      }
    }

    &__vrp {
      position: relative;
      text-align: center;
      margin-bottom: 80px;
      margin-top: 80px;
      p {
        margin-top: -150px;
        font-size: 38.5629px;
        line-height: 54px;
        font-weight: bold;

        .green {
          font-size: 69.4132px;
          line-height: 93px;
        }
      }
    }
    & > h4 {
      font-weight: bold;
      font-size: 30px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.005em;

      /* Primal Color */
      color: #263238;
    }

    .green {
      color: #2bb472;
    }

    & > p {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }

    .business__cards2 {
      display: flex;
      justify-content: center;
      & > div:first-child {
        margin-right: 100px;
      }

      & > div {
        display: flex;

        flex-direction: column;
        align-items: center;
        img {
          display: block;
          max-width: 330px;
          width: auto;
          height: 180px;
        }

        p {
          font-size: 24px;
          line-height: 32px;
          max-width: 350px;
          text-align: center;
          span {
            font-weight: bold;
            font-size: 36px;
            line-height: 48px;
            display: block;
          }
        }
      }
    }

    .business__cards3 {
      display: flex;
      flex-direction: column;

      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        max-width: 90%;

        img {
          height: fit-content;
        }
        p {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  .turism {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;

    .turism__card {
      width: 207px;

      &__icon {
        background: #f9f9fb;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        margin: auto;
      }
      img {
        display: block;
      }

      p {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }
    }
  }

  .culture {
    margin-top: 90px;
    .culture__cards {
      display: flex;
      margin-top: 16px;

      & > div {
        width: 150px;
        text-align: center;
        margin-right: 30px;
      }
      .culture__card {
        width: 150px;
        height: auto;
        margin-right: 30px;
        &__icon {
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 140px;
          height: 140px;
          margin: auto;
          margin-bottom: 15px;
        }
        img {
          display: block;

          margin-bottom: 20px;
        }

        p {
          font-weight: bold;
          font-size: 36px;
          line-height: 48px;
          text-align: center;
          margin: 0;
          color: #5b5b59;
        }
      }
    }

    .culture__cards2 {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      .culture__card {
        width: 350px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 24px;

        img {
          display: block;
          margin: auto;
          margin-top: 22px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          margin: 20px 20px;
          text-align: center;
        }
      }
    }
  }

  .cards {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .card {
      width: 350px;
      border-radius: 10px;

      img {
        display: block;
        margin: auto;
        margin-top: 22px;
      }

      p {
        font-size: 20px;
        line-height: 28px;
        margin: 20px 20px;
        text-align: center;
      }
    }
  }

  .cards2 {
    display: flex;
    margin-top: 90px;

    & > div {
      width: 250px;
      text-align: center;
      margin-right: 30px;
      & > p {
        font-size: 20px !important;
        line-height: 28px;
      }
    }

    .card {
      width: 250px;
      height: auto;
      background: #fff;
      border-radius: 24px;
      box-sizing: border-box;
      margin-right: 30px;
      padding-top: 44px;

      & > img {
        display: block;
        margin: auto;
        margin-bottom: 44px;
      }

      p {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        padding: 20px 20px;
        text-align: center;
        margin: 0;
        background-color: #eeeef4;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        color: #5b5b59;
      }
    }
  }

  .science {
    .cards2 {
      justify-content: center;
    }

    &__ranks {
      width: 730px;

      background: #2bb472;
      border-radius: 16px;
      color: #ffffff;
      padding: 32px;
      margin: 40px auto;
      display: flex;
      align-items: center;

      img {
        margin-right: 32px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__last {
      flex-wrap: wrap;
      justify-content: center;
      .card {
        width: 540px;
      }
    }
  }
}

.footer {
  margin-top: 0 !important;
}
