.bonus {
  display: flex;
  padding-top: 50px;

  .left {
    flex: 2;
    word-wrap: break-word;
    & > h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      margin: 0 0 50px 0;
    }
    .list_card {
      width: inherit;
      align-items: center;

      .list_card__info {
        max-width: 350px;

        & > div {
          & > div {
            font-size: 12px;
            line-height: 16px;
            color: #161a1780;
            margin-right: 10px;

            span {
              font-size: 12px;
              line-height: 16px;
              color: #161a17;
            }
          }
        }
      }
      .list_card__actions {
        border: 2px solid rgba(22, 26, 23, 0.08);
        width: 200px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        p,
        h3 {
          margin: 0;
        }

        h3 {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          /* identical to box height, or 133% */

          /* Green */
          color: #2bb472;

          .price_list {
            display: block;
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            color: #161a17;

            & > span {
              display: block;
            }

            .green {
              font-size: 16px;
              font-weight: bold;
              color: #2bb472;
            }
          }
        }

        p {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #161a17;
        }
      }
    }
  }

  .total_bonus {
    flex: 1;
    margin-left: 30px;
    height: 190px;
    background: linear-gradient(
      90deg,
      rgba(43, 180, 114, 0.1) 0%,
      rgba(43, 180, 114, 0) 100%
    );
    border-radius: 4px;

    h3 {
      font-weight: bold;
      font-size: 64px;
      line-height: 87px;

      /* Green */
      color: #2bb472;
      margin: 35px 0 5px 65px;
    }

    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-left: 65px;
      margin-top: 0;
    }
  }
}

.def_modal {
  width: 350px;
  max-height: 80vh;
  background-color: #fff;
  margin-top: 100px;
  overflow: auto;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
}

.bonus_modal {
  width: 350px;
  max-height: 80vh;
  background-color: #fff;
  margin-top: 100px;
  overflow: auto;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;

  & > h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    max-width: 350px;
  }

  & > img {
    max-width: 100%;
    max-height: 150px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }

  .info {
    background: rgba(22, 26, 23, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;

    h5 {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #0e0e2c;
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    span {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #2bb472;
    }
  }

  button {
    margin-bottom: 0;
  }

  .Mui-disabled {
    opacity: 0.5;
  }

  .MuiFormHelperText-root {
    font-weight: bold;
    font-size: 12px !important;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Red */
    color: #e84b55;
  }
}
