.history {
  & > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    margin: 60px 0;
  }
  .list_card {
    & > svg {
      margin-right: 30px;
    }
    .list_card__actions {
      display: flex;
      justify-content: flex-end;
      height: min-content;

      button {
        min-width: 54px !important;
        width: 54px;
        border: 2px solid rgba(22, 26, 23, 0.08);
        color: #263238;

        .MuiButton-startIcon {
          margin: 0 !important;
        }
      }
    }
  }
}
