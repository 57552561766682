@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

html,
body {
  min-height: 100%;
  height: 100%;
  color: #161a17;
  overflow: auto !important;
  padding-right: 0 !important;
}

body {
  font-family: Nunito, sans-serif;
}

p,
h4,
h5,
div {
  white-space: pre-line;
}

#root {
  min-height: 100%;
  height: 100%;
}

.main {
}

h1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 66px;
  line-height: 90px;
  color: #2bb472;
}

h2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
}

.MuiTab-root {
  font-family: "Nunito" !important;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end;
}

.m-l-auto {
  margin-left: auto;
}

.m-l-1 {
  margin-left: 1em;
}

.m-r-1 {
  margin-right: 1em;
}

.w-100 {
  width: 100% !important;
}

.wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  overflow-wrap: break-word;
}

.SnackbarItem-message-34 {
  font-family: Nunito;
}

a {
  text-decoration: none;
  color: #161a17;
}
a:hover {
  color: #2bb472 !important;
}

.layout {
  width: 1110px;
  margin: auto;
}

.list_card {
  display: flex;
  width: 100%;
  border: 2px solid rgba(22, 26, 23, 0.08);
  padding: 25px;
  margin-bottom: 30px;

  .thumb {
    width: 85px;
    height: 85px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-right: 30px;
  }
  .list_card__info {
    flex: 1;
    width: inherit;
    max-width: 65%;
    h3 {
      margin-top: 0;
      margin-bottom: 12px;
      white-space: pre-line;
      overflow-wrap: break-word;
    }
    p {
      font-size: 14px;
      white-space: pre-line;
      overflow-wrap: break-word;
    }
  }

  .dates {
    display: flex;
    align-items: center;
    color: #161a17;
    opacity: 0.5;
    font-size: 12px;
    line-height: 16px;
    svg {
      font-size: 16px;
      margin-right: 6.5px;
    }
  }

  .list_card__actions {
    width: 240px;
    margin-left: auto;
    button {
      width: 100%;
      margin-bottom: 0;
      padding: 6px 25px !important;

      .MuiButton-label {
        font-size: 20px !important;

        .MuiSvgIcon-root {
          font-size: 40px !important;
        }
      }
    }
    p {
      font-size: 12px;
      line-height: 16px;
      color: #161a1780;
      span {
        font-weight: bold;
        color: #2bb472;
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(43, 180, 114, 0.25);
  }
}

.menu__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 336px;
  z-index: 1000;
  background-color: #fff;

  .menu__wrapper__content {
    width: 1110px;
    margin: auto;

    .menu__btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 40px;
      svg {
        margin-right: 17px;
      }

      span {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      button {
        min-width: 150px;
        margin-top: 70px;
        text-align: left;
        margin-right: 30px;
      }
    }
  }
}

.modal {
  width: fit-content;
  height: fit-content;

  margin: auto;
  overflow: auto;
}
.modal:focus {
  outline: none !important;
}

.not_auth {
  opacity: 0 !important;
}

.bold {
  font-weight: bold;
}

.header {
  position: relative;
  display: flex;
  width: 1110px;
  height: 100px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  .menu {
    z-index: 2;
    .menu__btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #2bb472;

      svg {
        margin-right: 17px;
        filter: invert(65%) sepia(16%) saturate(1715%) hue-rotate(99deg)
          brightness(86%) contrast(90%);
      }

      span {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #2bb472;
      }
    }
  }

  .logo {
    // padding-right: 80px;
    // display: flex;
    // justify-content: flex-end;
    // flex: 1;
    cursor: pointer;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
  }

  .search {
    position: relative;
    padding-right: 15px;
    z-index: 2;

    .MuiInput-underline:before {
      border-bottom: none !important;
    }
    .input_text {
      position: relative;
    }

    input {
    }
  }
  .search:after {
    border-right: 1px solid #161a1726;
    left: 0;
    right: 0;
    content: "\00a0";
    position: absolute;
    z-index: -1;
  }

  .lang-picker {
    font-weight: bold;
    z-index: 2;
    .lang_btn {
      display: flex;
      align-items: center;
      .MuiIconButton-root {
        font-size: 14px;
        line-height: 20px;
        background-color: transparent;
        border: none;
        border-right-color: transparent;
        border-radius: 0;

        & > span {
          svg {
            margin-right: 10px;
          }
          // border-right: 1px solid #161a1726;
        }
      }

      .divider {
        width: 1px;
        height: 25px;
        background-color: #161a1726;
        margin-left: 13px;
        margin-right: 10px;
      }

      .MuiIconButton-root:hover {
        color: #2bb472;
      }
    }
    // svg {
    //   margin-right: 10px;
    // }
    // span {
    //   padding-right: 25px;
    //   margin-right: 15px;
    //   border-right: 1px solid #161a1726;
    // }
  }

  .user_thumb {
    display: flex;
    align-items: center;
    z-index: 2;

    .links {
      padding-left: 25px;
      padding-right: 10px;

      a {
        text-decoration: none;
        color: #161a17;
      }
      span {
        margin: 0 10px;
      }
    }

    button {
      margin-left: 13px;

      .thumb {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
      svg {
        border: 2px solid #2bb472;
        border-radius: 50%;
      }
    }
  }
}

.main {
  min-height: 80vh;

  .top {
    position: relative;
    width: 100%;
    min-height: 296px;

    .top__inner {
      width: 1110px;
      height: 100%;
      margin: auto;
      position: relative;
      padding-top: 38px;
      padding-bottom: 38px;

      .route_links {
        display: flex;
        align-items: center;

        .parent {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          position: relative;
          margin-right: 36px;
          cursor: pointer;
        }

        .parent::after {
          content: "\A";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #2bb472;
          display: inline-block;

          position: absolute;
          top: 45%;
          right: -20px;
        }
      }

      .top__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        margin-top: 48px;
        margin-bottom: 30px;
        overflow-wrap: break-word;

        .child {
          color: #161a1780;
        }
      }

      .top__desc {
        font-size: 20px;
        line-height: 28px;
        margin-top: 30px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .top_light {
    background-image: url(../../assets/images/top-back.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-color: #f8faf9;

    .child {
      color: #161a1780;
    }
  }

  .top_dark {
    // background-image: url(../../assets/images/top-back.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: cover;
    background-color: rgba(25, 25, 34, 0.5);
    color: #fff;

    .child {
      color: #fff;
      opacity: 0.5;
    }

    .top__over {
      position: absolute;
      top: 0;
      background-color: rgba(25, 25, 34, 0.5);
      width: 100%;
      height: 100%;
    }
  }
}

.footer {
  font-size: 16px;
  line-height: 24px;
  color: #161a1780;
  text-align: center;
  box-shadow: 0px -2px 0px #f8faf9;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
}

.menu__arrow {
  position: absolute;
  width: 24px;
  height: 8px;
  left: calc(50% - 24px / 2 + 24px);
  top: 1px;

  /* White */
  background: #ffffff;
  transform: rotate(-180deg);
}

.menu_appbar {
  margin-top: 65px;

  .MuiMenu-paper {
    box-shadow: none;
    background-color: transparent;
    // width: 230px;

    ul {
      margin: 10px;
      background: #ffffff;
      box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      li {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .MuiMenu-paper::before {
    content: "";
    position: absolute;
    width: 0px;
    height: 8px;
    right: 10px;
    top: -28px;
    z-index: 2;
    border: 20px solid transparent;
    border-bottom: 20px solid #fff;
  }
}

.main_page {
  position: relative;

  .header {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    // svg,
    // a {
    //   color: #fff !important;
    // }

    .search:after {
      border-right: 1px solid #f8faf926;
    }

    .lang-picker {
      font-weight: bold;
      .lang_btn {
        display: flex;
        align-items: center;
        .MuiIconButton-root {
          font-size: 14px;
          line-height: 20px;
          background-color: transparent;
          border: none;
          border-right-color: transparent;
          border-radius: 0;

          & > span {
            svg {
              margin-right: 10px;
            }
            // border-right: 1px solid #161a1726;
          }
        }

        .divider {
          width: 1px;
          height: 25px;
          background-color: #5d615ea8;
          margin-left: 13px;
          margin-right: 10px;
        }

        .MuiIconButton-root:hover {
          color: #2bb472;
        }
      }
      // svg {
      //   margin-right: 10px;
      // }
      // span {
      //   padding-right: 25px;
      //   margin-right: 15px;
      //   border-right: 1px solid #161a1726;
      // }
    }
  }

  .text_slides {
    position: relative;
    // color: #fff;
    background-image: url("../../assets/images/main-bgr.svg");
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-color: #f8faf9;

    .news_link {
      position: absolute;
      right: 0;
      bottom: 10%;
      z-index: 100;
      cursor: pointer;
      svg {
        color: #fff;
        width: 50px;
        height: 50px;
      }
    }
    .slick-slider {
      position: relative;
    }

    .text_slides__info {
      background-size: cover;
      background-position: center;
      height: calc(100vh - 100px);
      position: relative;
      // margin-top: -16px;

      .overlay {
        position: absolute;
        top: 0;
        background-color: rgba(25, 25, 34, 0.5);
        width: 100%;
        height: 100%;
      }

      .layout {
        position: relative;
        padding-top: 50px;
        height: inherit;

        & > div:first-child {
          height: 50%;
        }
      }

      .date {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      hr {
        margin-bottom: 40px;
      }

      h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
      }

      .desc {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 40px;
      }
    }
    .slick-dots {
      width: 1110px;
      position: absolute;
      bottom: 20vh;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      ul {
        display: flex;
        padding: 0;
        li {
          flex: 1;
          max-width: 250px;
          margin-right: 30px;
          .text_slides__page {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 110px;
            text-align: left;

            background: #fff;
            border-radius: 4px;
            color: #161a17;
            padding: 24px;

            h3 {
              margin-bottom: 0;
              margin-top: 0;
              font-size: 14px;
            }
            p {
              margin-bottom: 0;
              margin-top: 0;
              font-size: 14px;
            }
            .date {
              font-size: 14px;
            }
          }
        }

        .slick-active {
          .text_slides__page {
            background: #2bb472;
            border-radius: 4px;
            color: #fff;
            padding: 24px;
          }
          opacity: 1;
        }
      }
    }
  }

  .links_section {
    padding-top: 100px;
    padding-bottom: 100px;

    h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 30px;
      margin-top: 100px;
    }

    hr {
      margin-bottom: 30px;
    }

    & > p {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 50px !important;
    }

    .link_card {
      width: 350px;
      height: 142px;
      display: flex;
      padding: 40px;
      padding-right: 30px;
      border: 2px solid rgba(22, 26, 23, 0.08);
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 30px;

      span {
        margin-right: 20px;
      }
      p {
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
      }

      button {
        display: flex;
        align-items: center;
        margin: 0;
        span {
          margin: 0;
          color: #161a17;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          svg {
            margin-left: 16px;
            color: #2bb472;
          }
        }
      }
    }

    .link_card:hover {
      border: 2px solid #2bb472;
      box-sizing: border-box;
      box-shadow: 0px 8px 16px rgba(43, 180, 114, 0.25);
      cursor: pointer;
      color: #2bb472;

      button {
        span {
          color: #2bb472;
        }
      }
    }
  }

  .news {
    background: #f8faf9;
    padding-top: 100px;
    padding-bottom: 100px;

    & > div {
      & > h3 {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 30px;
      }

      & > hr {
        margin-bottom: 30px;
      }

      & > p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 50px !important;
      }

      .news_card {
        width: 255px;
        height: 269px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 30px;

        img {
          width: 225px;
          height: auto;
          border-radius: 4px;
        }

        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          margin: 10px 0;
        }

        span {
          font-size: 12px;
          line-height: 16px;
          opacity: 0.5;
          margin-top: 10px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          flex: 1;
          display: flex;
          align-items: flex-end;
        }
      }

      .news_card:hover {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .load_more {
        justify-content: start;
        margin-top: 50px;
        margin-bottom: 0;
      }
    }
  }

  footer {
    width: 100%;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 16px;
      line-height: 24px;
      opacity: 0.5;
    }
  }
}

.auth {
  display: flex;
  height: 100%;

  h1 {
  }

  .auth__left {
    flex: 1;
    background-color: #f8faf9;
    background-image: url(../../assets/images/login_background.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;

    .auth__left__inner {
      width: 445px;
      margin: 100px 95px auto auto;

      h1 {
        font-size: 36px;
        line-height: 48px;
      }
      p {
        font-size: 24px;
        line-height: 36px;
        color: #2bb472;
      }
    }
  }

  .auth__right {
    flex: 1;
    min-height: 100vh;
    overflow-y: auto;

    .auth__right__inner {
      width: 445px;

      margin: 100px auto 100px 95px;

      & > p {
        font-size: 20px;
        line-height: 28px;
        margin-top: 30px;
      }

      .count-down {
        color: #2bb472;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.info {
  overflow-wrap: break-word;
}

.vote_more_btn {
  position: absolute;
  top: 20%;
  font-size: 16px;
  font-weight: bold;
}

.vote {
  .vote__info {
    background-color: #f8faf9;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 4px;

    .label {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #161a17;
      opacity: 0.5;
      margin-bottom: 0 !important;
    }

    .info {
      font-size: 16px;
      line-height: 24px;
      color: #161a17;
      margin-top: 0;

      & > div {
        display: flex;

        span {
          display: flex;
          align-items: center;
          margin-right: 10px;

          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .vote_questions {
    .vote_questions__title {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
    }

    .vote_question__label {
      background: linear-gradient(
        90deg,
        #f8faf9 0%,
        rgba(248, 250, 249, 0) 100%
      );
      padding: 5px 30px;
      min-height: 80px;
      margin: 0 0 20px 0;

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }
      img {
        max-height: 70px;
        margin-right: 20px;
      }
    }

    &__files {
      display: flex;
      flex-wrap: wrap;

      img,
      .link {
        display: block;
        margin-right: 15px;
        margin-bottom: 15px;
        max-width: 200px;
        cursor: pointer;
      }
    }

    .link {
      max-width: 200px;
      margin-right: 15px;
    }

    .selected {
      span {
        color: #2bb472;
      }
    }

    .vote_questions__answers {
      & > div {
        width: 100%;
        min-height: 82px;
        padding: 5px 30px;
        margin: 0 0 20px 0;

        background: linear-gradient(
          90deg,
          rgba(43, 180, 114, 0.2) 0%,
          rgba(43, 180, 114, 0.2) 50.51%,
          #f8faf9 50.52%,
          rgba(248, 250, 249, 0) 100%
        );
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        border: 2px solid #2bb472;

        & > span {
          width: 10%;
          color: #2bb472;
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-right: 20px;
        }
        img {
          max-height: 70px;
          margin-right: 20px;
        }
      }
    }
  }
}

.suggestion {
  padding-top: 30px;
  .suggestion__info {
    background-color: #f8faf9;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 4px;

    .label {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #161a17;
      opacity: 0.5;
      margin-bottom: 0 !important;
    }

    .info {
      font-size: 16px;
      line-height: 24px;
      color: #161a17;
      margin-top: 0;

      & > div {
        display: flex;

        span {
          display: flex;
          align-items: center;
          margin-right: 10px;

          svg {
            margin-left: 5px;
          }
        }
      }
    }

    img {
      width: 300px;
      height: auto;
      margin-right: 15px;
      border: 2px solid rgba(22, 26, 23, 0.08);
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .comments {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    & > .comment_container > .comments__childs {
      margin-left: 50px;
    }
    .comments-form {
      overflow-y: auto;
      & > div {
        position: relative;
      }
    }
    .comment_container {
      .comment {
        border: 2px solid rgba(22, 26, 23, 0.1);
        border-radius: 4px;
        box-shadow: none;
        margin-bottom: 30px;

        .MuiCardHeader-root {
          height: 57px;
          padding: 0 16px !important;
          border-bottom: 2px solid rgba(22, 26, 23, 0.1);

          .MuiCardHeader-avatar {
            display: flex;
          }

          .MuiCardHeader-content {
            span {
              display: block;
            }
            .title {
              font-family: Nunito;
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
            }
            .dates {
              font-family: Nunito;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #161a17;
              opacity: 0.5;
              text-align: right;
              margin: 0;
            }
          }
        }

        .MuiCardContent-root {
          p {
            font-family: Nunito;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .MuiCardActions-root {
          padding: 0 16px;
          display: flex;
          justify-content: space-between;

          .rating {
            display: flex;

            .MuiButton-label {
              display: flex;
              align-items: center;
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              color: #161a1782;
            }
          }
        }
      }
    }

    form {
      textarea {
        height: 200px !important;
        overflow-y: auto !important;
      }
    }
  }
}

.city_novelty {
  padding-top: 50px;
  .list_card__actions {
    display: flex;
    justify-content: flex-end;
    height: min-content;

    button {
      min-width: 54px !important;
      width: 54px;
      border: 2px solid rgba(22, 26, 23, 0.08);
      color: #263238;

      .MuiButton-startIcon {
        margin: 0 !important;
      }
    }
  }
}

.profile_settings {
  & > h3,
  & > form > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    margin: 60px 0;
  }

  .profile_settings__thumb {
    display: flex;
    .image {
      // width: 142px;
      // height: auto;
      // svg {
      //   width: 100%;
      //   height: 100%;
      // }
      .thumb {
        width: 142px;
        height: 142px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;

      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }

      & > span {
        font-size: 12px;
        line-height: 16px;
        color: #161a17;
        opacity: 0.5;
      }
    }
  }
}

.user_bonus {
  .user_bonus__banner {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(
      90deg,
      rgba(43, 180, 114, 0.1) 0%,
      rgba(43, 180, 114, 0) 100%
    );
    border-radius: 4px;

    .info {
      padding: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-weight: bold;
        font-size: 64px;
        margin: 0;
        color: #2bb472;
      }

      p {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        display: flex;

        span {
          display: flex;
          align-items: center;
          margin-left: 10px;
          color: #2bb472;
          font-size: 20px;
          cursor: pointer;
        }
      }

      button {
        margin: 0;
      }
    }
  }
}

.user_bonus__history,
.alert_history {
  & > h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
  }

  .list_card {
    .icon {
      width: 70px;
      height: 70px;

      background: rgba(43, 180, 114, 0.1);
      border-radius: 50%;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #2bb472;
    }
  }
}

.total_bonus {
  p {
    display: flex;
    align-items: center;
    span {
      display: flex;
      cursor: pointer;
    }
  }
}

.bonus_modal {
  .actions {
    margin-top: 15px !important;
  }
}

.votes_info_modal {
  margin-top: 35vh !important;
}

.link {
  a {
    display: block;
    cursor: pointer;
    margin-bottom: 15px;
    color: #2bb472;
  }
}

.txt_count {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 10;
}
