.city_novelty__view {
  width: 730px;
  margin: auto;
  padding-top: 50px;

  img {
    width: 300px;
    height: auto;
    margin-right: 15px;
    border: 2px solid rgba(22, 26, 23, 0.08);
    border-radius: 8px;
    cursor: pointer;
  }
}
